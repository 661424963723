<template>
  <div class="common-style" @click="isInfo = false">
    <!-- 公用头部 -->
    <div class="head">
      <div class="head_alias">
        <!-- <div class="head_logo"></div> -->
        <div class="head_title">
          <div class="head_b">
            <img src="@/assets/logo.png" class="head_b_logo" />
          </div>
        </div>
        <div class="head_logout">
          <el-badge
            :is-dot="isHot"
            class="msg_badge"
            style="margin-right: 52px"
          >
            <img
              src="@/assets/login/bell.png"
              class="systemnew_icon"
              @click="jumpNotice"
            />
          </el-badge>

          <div class="logo_top_one" @click.stop>
            <div class="logo_top" @click="isInfo = true">
              <img v-if="user.headUrl" class="head-img" :src="user.headUrl" />
              <img v-else class="head-img" src="@/assets/head.png" />
              <span style="line-height: 20px">
                <div style="font-weight: bold">{{ user.name }}</div>
                <div v-if="user.status == 3" class="administrators">管理员</div>
                <div v-if="user.status == 4" class="administrators">
                  超级管理员
                </div>
              </span>
            </div>
            <div class="logo_down_list">
              <div class="user_down" v-if="isInfo">
                <div class="user_down_top">
                  <img
                    v-if="user.headUrl"
                    class="head-img2"
                    :src="user.headUrl"
                  />
                  <img v-else class="head-img2" src="@/assets/head.png" />
                  <div class="user_down_name">{{ user.name }}</div>
                  <div class="user_down_position" v-if="user.status == 3">
                    管理员
                  </div>
                  <div class="user_down_position" v-if="user.status == 4">
                    超级管理员
                  </div>
                  <div class="user_down_company" v-if="user.umsCompany">
                    <div class="user_down_company_name">
                      {{ user.umsCompany.name }}
                    </div>
                    <img
                      @click="editCompany"
                      src="@/assets/edit.png"
                      class="user_down_company_edit"
                      v-if="user.status == 4"
                    />
                  </div>
                </div>
                <div class="switch">
                  <div class="switch_bto">
                    <div>切换企业</div>
                    <img src="@/assets/right.png" class="switch_bto_icon" />
                  </div>
                  <div class="company_list">
                    <div
                      @click="switchCompany(item)"
                      v-for="item in companyList"
                      :class="[
                        'company_single',
                        item.umsCompany.name == user.umsCompany.name
                          ? 'company_single_text_select'
                          : '',
                      ]"
                    >
                      <div class="company_single_ledt">
                        <img
                          v-if="item.umsCompany.name == user.umsCompany.name"
                          src="@/assets/selectcompany.png"
                          class="company_single_icon"
                        />
                        <img
                          v-else
                          src="@/assets/company.png"
                          class="company_single_icon"
                        />
                        <div class="company_single_text">
                          {{ item.umsCompany.name }}
                        </div>
                      </div>
                      <img
                        v-if="item.umsCompany.name == user.umsCompany.name"
                        src="@/assets/success.png"
                        class="company_single_success"
                      />
                    </div>
                  </div>
                </div>
                <div class="user_down_loout" @click="logout = true">
                  <span class="logout_down_text">退出登录</span>
                </div>
              </div>
            </div>

            <!-- 下拉列表 -->
          </div>
        </div>
      </div>
    </div>
    <div class="partition">
      <!-- 左侧导航栏 -->
      <div class="navigation">
        <el-menu
          :default-active="$route.path"
          class="common_nav"
          background-color="#FFFFFF"
          text-color="#1A1A1A"
          active-text-color="#0058FF"
          router
        >
          <div v-for="item in showMenu" :key="item.id">
            <div>
              <el-submenu :index="item.id" v-if="item.chidren">
                <template slot="title">
                  <img :src="item.icon" class="common_nav_single_icon" />
                  <span class="nav_t">{{ item.name }}</span>
                </template>
                <el-menu-item-group
                  v-for="(ditem, index) in item.chidren"
                  :key="ditem.nav"
                >
                  <el-menu-item
                    :index="ditem.nav"
                    style="display: flex"
                    :route="ditem.nav"
                  >
                    <div style="width: 30px"></div>
                    <span slot="title">{{ ditem.name }}</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item :index="item.nav" v-else :route="item.nav">
                <img
                  :src="item.selecticon"
                  class="common_nav_single_icon"
                  v-if="$route.path == item.nav && item.nav == '/bulletin'"
                />
                <img :src="item.icon" class="common_nav_single_icon" v-else />
                <span slot="title" class="nav_t">{{ item.name }}</span>
              </el-menu-item>
            </div>
          </div>
        </el-menu>
      </div>
      <!-- 右侧内容 -->
      <div class="substance">
        <router-view />
      </div>
    </div>
    <!-- 退出登录 -->
    <el-dialog
      width="640px"
      height="266px"
      title="退出登录"
      :visible.sync="logout"
      :close-on-click-modal="false"
    >
      <div class="logout_body">退出登录后，你将无法收到该帐号的通知</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="logout_confirm"
          style="background: none; margin-right: 20px"
          @click="logout = false"
          >取 消</el-button
        >
        <el-button type="primary" class="logout_confirm" @click="loginout"
          >退出登录</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改公司名称 -->
    <el-dialog
      title=""
      :visible.sync="editCompanyDialog"
      append-to-body
      width="33%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_header">
        <div>更改公司名称</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon_close"
          @click="cancelEditCompany"
        />
      </div>

      <div class="diglog_body">
        <el-input
          v-model="editCompanyName"
          placeholder="请输入手机号"
        ></el-input>
      </div>

      <div class="diglog_operation_other">
        <el-button @click="cancelEditCompany">取消</el-button>
        <el-button type="primary" @click="comfinEditCompany">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import Icon1 from "../assets/structure/icon1.png";
import Icon2 from "../assets/structure/icon2.png";
import Icon3 from "../assets/structure/icon3.png";
import Icon4 from "../assets/structure/icon4.png";
import Icon5 from "../assets/structure/icon5.png";
import Icon6 from "../assets/structure/icon6.png";
import Icon7 from "../assets/structure/icon7.png";
import Icon8 from "../assets/structure/icon8.png";
import http from "../utils/http";
export default {
  data() {
    return {
      isHot: false, //消息未读数量
      inverterMonTimer: null, //定时器
      loadingInstance1: null,
      user: {}, //用户信息
      logout: false,
      editCompanyDialog: false, //更改公司弹窗
      editCompanyName: "", //公司名称
      isInfo: false, //是否显示个人信息
      companyList: [], //公司列表
      showMenu: [],
      navList: [
        {
          name: "数据看板",
          id: "1",
          icon: Icon1,
          selecticon: Icon8,
          nav: "/bulletin",
        },
        {
          name: "组织架构",
          id: "2",
          icon: Icon2,
          chidren: [
            {
              name: "部门管理",
              id: "3",
              nav: "/departmentManagement",
            },
            {
              name: "人员管理",
              id: "4",
              nav: "/structure",
            },
          ],
        },
        {
          name: "审批管理",
          id: "5",
          icon: Icon3,
          chidren: [
            {
              name: "事件审批",
              id: "11",
              nav: "/eventApproval",
            },
            {
              name: "人员审批",
              id: "12",
              nav: "/persionalApproval",
            },
          ],
        },
        {
          name: "考勤管理",
          id: "6",
          icon: Icon4,
          chidren: [
            {
              name: "班次设置",
              id: "13",
              nav: "/classes",
            },
            {
              name: "考勤组管理",
              id: "14",
              nav: "/group",
            },
            {
              name: "小程序打卡",
              id: "15",
              nav: "/wechat",
            },
            {
              name: "登记加班",
              id: "16",
              nav: "/workovertime",
            },
            {
              name: "登记补卡",
              id: "17",
              nav: "/replacement",
            },
            {
              name: "登记请假",
              id: "18",
              nav: "/leave",
            },
            {
              name: "登记出差",
              id: "19",
              nav: "/businesstrips",
            },
            {
              name: "登记外出",
              id: "20",
              nav: "/goout",
            },
          ],
        },
        {
          name: "考勤报表",
          id: "7",
          icon: Icon5,
          chidren: [
            {
              name: "原始记录表",
              id: "21",
              nav: "/original",
            },
            {
              name: "打卡记录表",
              id: "22",
              nav: "/chockin",
            },
            {
              name: "个人考勤明细",
              id: "23",
              nav: "/personal",
            },
            {
              name: "个人考勤汇总",
              id: "24",
              nav: "/personalsummary",
            },
            {
              name: "部门考勤汇总",
              id: "25",
              nav: "/departmentsummary",
            },
          ],
        },
        {
          name: "设备管理",
          id: "8",
          icon: Icon6,
          chidren: [
            {
              name: "设备列表",
              id: "26",
              nav: "/device",
            },
            {
              name: "设备用户管理",
              id: "27",
              nav: "/deviceMember",
            },
            {
              name: "下发任务列表",
              id: "28",
              nav: "/distribute",
            },
          ],
        },
        {
          name: "系统设置",
          id: "9",
          icon: Icon7,
          chidren: [
            {
              name: "登录方式",
              id: "29",
              nav: "/loginmethod",
            },
            {
              name: "修改密码",
              id: "30",
              nav: "/updatepass",
            },
            {
              name: "角色权限",
              id: "31",
              nav: "/role",
            },
            {
              name: "帮助中心",
              id: "32",
              nav: "/help",
            },
            {
              name: "关于我们",
              id: "33",
              nav: "/aboutus",
            },
          ],
        },
      ],
    };
  },
  created() {
    // 获取个人信息
    this.getUserinfo();

    var token = localStorage.getItem("token");
    if (token) {
      this.loadingInstance1 = this.$loading({
        lock: true,
        text: "读取数据中..",
        spinner: "el-icon-loading",
        background: "rgba(250, 250, 250, 1)",
      });
      this.pollingList();
    } else {
      // 清楚轮询
      this.beforeDestroy();

      this.$router.replace("/");
      // 清除缓存
      localStorage.clear();
    }
  },
  mounted() {
    this.getInverterMonTimer();
  },
  methods: {
    loginout() {
      var _this = this;
      _this.beforeDestroy();
      _this.$message.warning("您已退出登陆");
      _this.$router.replace("/");
      // 清除缓存
      localStorage.clear();
    },

    // 轮询审核列表
    pollingList() {
      http
        .get("/bmsApply/list", {
          statusList: "0",
          pageSize: 1,
          pageNum: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              this.isHot = true;
            } else {
              this.isHot = false;
            }
          }
        });
    },
    // 清空定时器
    beforeDestroy() {
      if (this.inverterMonTimer) {
        clearInterval(this.inverterMonTimer);
        this.inverterMonTimer = null;
      }
    },
    // 轮询
    getInverterMonTimer() {
      var that = this;
      // 判断定时刷新是否存在，存在先清除
      if (that.inverterMonTimer) {
        clearInterval(that.inverterMonTimer);
        that.inverterMonTimer = null;
      }
      // 实现轮询 两分钟执行一下
      that.inverterMonTimer = setInterval(() => {
        that.pollingList();
      }, 15000);
    },
    // 跳转消息列表
    jumpNotice() {
      this.$router.push({
        path: "/eventApproval",
      });
    },
    // 获取个人信息
    getUserinfo() {
      var that = this;
      http.get("/umsSysUser/get", {}).then((res) => {
        this.loadingInstance1.close();
        if (res.code == 200) {
          var company = localStorage.getItem("company");
          if (company) {
            company = JSON.parse(company);
          }

          localStorage.setItem("other", JSON.stringify(res.data));
          res.data.umsUserGetVOList.map((item) => {
            if (item.status == 3 || item.status == 4) {
              this.companyList.push(item);
            }
          });

          var value = res.data.umsUserGetVOList.find(
            (item) =>
              item.company == company.id &&
              (item.status == 3 || item.status == 4)
          );
          if (value) {
            localStorage.setItem("userinfo", JSON.stringify(value));
            that.user = value;

            if (value.status == 2) {
              this.loginout();
              return;
            }

            // // 管理员不显示角色权限
            if (value.status == 3) {
              that.navList[6].chidren.splice(2, 1);
              let list = [];
              value.umsUserPermissionList.map((item) => {
                list.push(item.permission);
              });

              // 人员管理
              var idx2 = list.findIndex((item) => {
                return item.permission == 3;
              });
              // 部门管理
              var idx3 = list.findIndex((item) => {
                return item.permission == 4;
              });

              var a = [];
              for (const f of that.navList) {
                var i = Array.from(list).indexOf(parseInt(f.id));
                if (i >= 0) a.push(f);
              }

              that.showMenu = a;

              // 分情况跳转
              // 1.如果是用户自己刷新
              if (
                this.$route.path == "/schedule" ||
                this.$route.path == "/addgroup"
              ) {
                this.loadingInstance1.close();
                return;
              } else {
                var index = this.getParentId(a, this.$route.path);
                if (index) {
                  this.loadingInstance1.close();
                  return;
                } else {
                  setTimeout(() => {
                    this.loadingInstance1.close();
                    if (a.length > 0) {
                      if (a[0].chidren) {
                        if (this.$route.path == a[0].chidren[0].nav) {
                          return;
                        }
                        that.$router.replace({
                          path: a[0].chidren[0].nav,
                          query: {},
                        });
                      } else {
                        if (this.$route.path == a[0].nav) {
                          return;
                        }
                        that.$router.replace({
                          path: a[0].nav,
                          query: {},
                        });
                      }
                    }
                  }, 1000);
                }
              }
            } else {
              this.loadingInstance1.close();
              that.showMenu = that.navList;
            }
          } else {
            that.$message.error("账号信息发生变化，已退出登陆");
            that.$router.replace("/");
            // 清除缓存
            localStorage.clear();
          }
        }
      });
    },

    // 递归查询
    getParentId(list, iid) {
      for (let o of list || []) {
        if (o.nav == iid) return o;
        const o_ = this.getParentId(o.chidren, iid);
        if (o_) return o_;
      }
    },

    // 修改公司名称
    editCompany() {
      this.editCompanyDialog = true;
      if (this.user.umsCompany) {
        this.editCompanyName = this.user.umsCompany.name;
      }
    },
    // 取消修改公司名称
    cancelEditCompany() {
      this.editCompanyDialog = false;
      this.editCompanyName = "";
    },

    // 确认修改公司名称
    comfinEditCompany() {
      if (!this.editCompany) {
        return;
      }
      http
        .post("/umsCompany/update", {
          name: this.editCompanyName,
        })
        .then((res) => {
          if (res.code == 200) {
            location.reload();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 切换公司
    switchCompany(item) {
      if (item.company == this.user.company) {
        return;
      }
      http
        .post("/umsSysUser/switch_company", {
          company: item.company,
        })
        .then((res) => {
          if (res.code == 200) {
            localStorage.setItem("company", JSON.stringify(item.umsCompany));
            location.reload();
          }
        });
    },
  },
};
</script>

<style scoped>
.common-style {
  height: 100%;
}
.head {
  height: 68px;
  width: 100%;
  padding-left: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.16);
}

.head_logo {
  width: 48px;
  background-color: #7e84a3;
  height: 48px;
  border-radius: 4px;
  margin-right: 14px;
}

.head_alias {
  width: 100%;
  display: flex;
  align-items: center;
}

.head_title {
  margin-left: 10px;
}

.head_b {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: bold;
}

.head_e {
  font-size: 12px;
  letter-spacing: 1.5px;
}

.head_logout {
  width: 30%;
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #faf9f9;
}

.systemnew_icon {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
}

.logo_top {
  display: flex;
  align-items: center;
  color: #131523;
  font-size: 14px;
  cursor: pointer;
}
.logo_top_one {
  position: relative;
}
.logo_down_list {
  display: flex;
  position: relative;
  align-items: center;
  color: #131523;
  font-size: 14px;
}

.logo_down {
  font-size: 18px;
  margin-left: 30px;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}

.user_down {
  width: 336px;
  height: 364px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: absolute;
  top: 13px;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.user_down_top {
  display: flex;
  height: 233px;
  flex-direction: column;
  align-items: center;
}
.user_down_loout {
  width: 100%;
  height: 65px;
  cursor: pointer;
  line-height: 65px;
  text-align: center;
  color: #ff0000;
  font-size: 18px;
  border-top: 1px solid #cfd4e8;
  box-sizing: border-box;
}

.user_down_text {
  font-size: 12px;
  text-align: center;
  color: #0058ff;
  background: #e0ecff;
}

.logout_down_text {
  line-height: 45px;
  font-size: 15px;
}

.logo_head {
  width: 37px !important;
  height: 37px !important;
}

.head-img {
  width: 36px;
  object-fit: cover;
  height: 36px;
  border-radius: 50%;
  margin-right: 13px;
}

.partition {
  display: flex;
}

.navigation {
  width: 250px;
  height: calc(100vh - 68px);
}

.common_nav {
  height: 100%;
  overflow-y: auto;
  padding: 30px 20px 0 0;
  box-sizing: border-box;
}

.common_nav_single_icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.nav_t {
  font-size: 16px;
}

.substance {
  flex: 1;
}

.logout_body {
  margin: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: 50px;
}

.logout_confirm {
  background: #0058ff;
  font-size: 15px;
  width: 100px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.administrators {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0ecff;
  border-radius: 2px;
  color: #0058ff;
  font-size: 12px;
  margin-top: 3px;
}

.logouttext {
  line-height: 45px;
  font-size: 15px;
}

::v-deep .el-menu-item:hover {
  background-color: rgba(204, 204, 204, 0.3) !important;
}

::v-deep .el-submenu__title:hover {
  background-color: rgba(204, 204, 204, 0.3) !important;
}
.head-img2 {
  width: 84px;
  height: 84px;
  margin-top: 24px;
  border-radius: 50%;
}
.user_down_name {
  font-size: 18px;
  color: #1a1a1a;
  margin: 10px 0 3px;
}
.user_down_position {
  font-size: 15px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 10px;
}
.user_down_company {
  font-size: 18px;
  color: #1a1a1a;
  display: flex;
  align-items: center;
}
.user_down_company_edit {
  width: 17px;
  height: 17px;
  margin-left: 8px;
  cursor: pointer;
}
.diglog_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 0 24px 0 24px;
  height: 73px;
  border-bottom: 1px solid #cfd4e8;
  color: #1a1a1a;
}
.diglog_icon_close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation_other {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.diglog_body {
  margin: 30px 0 40px;
  padding: 0 30px;
  box-sizing: border-box;
}
::v-deep .diglog_body .el-input {
  height: 56px;
}
::v-deep .diglog_body .el-input__inner {
  height: 56px;
  background: #f2f2f2;
}
::v-deep .diglog_operation_other .el-button {
  width: 100px;
  height: 40px;
  margin-right: 30px;
}
.switch {
  width: 100%;
  height: 66px;
  position: relative;
  border-top: 1px solid #cfd4e8;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 4px;
}
.company_list {
  position: absolute;
  top: 0;
  left: -336px;
  width: 336px;
  height: 400px;
  overflow-y: scroll;
  background: white;
  display: none;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.16);
  border-radius: 10px 10px 10px 10px;
  padding: 4px;
  box-sizing: border-box;
}
.company_single {
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  padding: 0 11px 0 8px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
}
.company_single_ledt {
  display: flex;
  align-items: center;
}
.company_single_icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.company_single_text {
  width: 216px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 超出容器的文本隐藏 */
  text-overflow: ellipsis;
  font-size: 18px;
  color: #1a1a1a;
}
.company_single_success {
  width: 18px;
  height: 12px;
}
.switch_bto {
  font-size: 18px;
  width: 100%;
  height: 100%;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
}
.switch_bto:hover {
  background: rgba(0, 0, 0, 0.06);
}
.switch_bto:hover + .company_list {
  display: block;
}
.company_list:hover {
  display: block;
}
.switch_bto_icon {
  width: 8px;
  height: 15px;
}
.company_single_text_select {
  background: rgba(0, 0, 0, 0.06);
}
.head_b_logo {
  width: 360px;
  height: 41px;
}
.user_down_company_name {
  max-width: 200px; /* 设置容器宽度 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}
</style>