<template>
  <div class="record common-style common-table_style">
    <div class="record_head">
      <span style="color: #b2b2b2">设备管理 /</span>
      <span style="color: #5c5c5c"> 下发列表</span>
    </div>
    <div class="record_wrapper">
      <div class="record_search_wrapper">
        <div class="record_search">
          <div class="record_search_item record_search_item_n">
            <div class="approval_search">
              <el-input
                v-model="passform.likeString"
                @keyup.enter.native="onSearch"
                placeholder="姓名、员工工号、设备名称…"
                class="department_search_inp"
                style="width: 200px"
              ></el-input>

              <img
                src="@/assets/structure/delete.png"
                class="searchicon"
                @click="clearup"
                v-if="searchName"
              />
              <img
                src="@/assets/structure/search.png"
                class="searchicon"
                @click="onSearch"
              />
            </div>
          </div>
          <div class="device_search_h record_search_item distribute_select">
            <el-select
              clearable
              v-model="passform.type"
              @change="onSearch"
              placeholder="操作类型"
              class="device_search_t"
            >
              <el-option
                v-for="item in optionsStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="device_search_h record_search_item">
            <el-select
              clearable
              v-model="passform.statusList"
              @change="onSearch"
              placeholder="操作结果"
              class="device_search_t"
            >
              <el-option
                v-for="item in optionsResult"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="record_export">
          <el-button style="color: #7e84a3" @click="startDownload"
            >导出报表</el-button
          >
        </div>
      </div>
      <div class="record_table_wrapper">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="passList"
          row-key="id"
          @selection-change="handleSelectionChange"
          :header-cell-style="{
            background: '#F5F6FA',
            color: '#5C5C5C',
          }"
          height="96%"
          class="record_table common_text"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata_common" />
              <div class="nodata_text">无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" reserve-selection>
          </el-table-column>
          <el-table-column
            label="姓名"
            header-align="left"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.umsUser">{{ scope.row.umsUser.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="department.name"
            label="员工工号"
            header-align="left"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.umsUser">{{
                scope.row.umsUser.jobNumber
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="department.name"
            label="部门"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.umsDepartment" class="dept">
                {{ scope.row.umsDepartment.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="device.name"
            label="设备名称"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.dmsDevice">{{
                scope.row.dmsDevice.name
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="device.sn"
            label="云序列号"
            header-align="left"
            align="left"
            width="200"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.dmsDevice">{{
                scope.row.dmsDevice.sn
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作类型"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                {{ distributionStatus(scope.row.type) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作结果"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                {{ distributionReasult(scope.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="reason"
            label="失败原因"
            header-align="left"
            align="left"
            width="250"
          >
            <template slot-scope="scope">
              <div>
                {{ distributionReasult2(scope.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            header-align="left"
            align="left"
            width="190"
          >
          </el-table-column>
          <el-table-column
            prop="timeFinish"
            label="完成时间"
            header-align="left"
            align="left"
            width="200"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="left"
            align="left"
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <div class="operate">
                <el-link
                  type="primary"
                  @click="resetTask(scope.row.id)"
                  style="margin-right: 10px"
                  v-if="
                    scope.row.status != 0 &&
                    scope.row.status != 2 &&
                    scope.row.type != '-10'
                  "
                  >重置任务</el-link
                >
                <el-link
                  type="primary"
                  @click="deleteTask(scope.row.id)"
                  v-if="
                    scope.row.status == 1 &&
                    scope.row.type == '-10' &&
                    judgeMinute(scope.row.updateTime)
                  "
                  >删除任务</el-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-if="passList != 0"
        class="newpagination"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="50"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import http from "../../utils/http";
import exprotExcel from "../../utils/exprotExcel";
export default {
  components: {},
  data() {
    return {
      noimg: "@/assets/structure/close.png",
      prangge: {},
      passList: [],
      passform: {},
      openImg: false, //实时照片弹窗
      dialogRange: false, //选择人员范围
      isSearchResult: false,
      imgNum: 0,
      loading: false, //加载表格
      bigImg: "",
      tableData: [],
      value: "",
      options: [
        {
          value: 0,
          label: "员工",
        },
        {
          value: 1,
          label: "访客",
        },
      ],
      optionsStatus: [
        {
          value: 0,
          label: "下发人员",
        },
        {
          value: 1,
          label: "删除人员",
        },
        {
          value: 2,
          label: "登记人脸",
        },
        {
          value: 3,
          label: "录入第一枚指纹",
        },
        {
          value: 8,
          label: "录入第二枚指纹",
        },
        {
          value: 9,
          label: "录入第三枚指纹",
        },
        {
          value: 4,
          label: "登记掌静脉",
        },
        {
          value: 5,
          label: "登记管理员",
        },
        {
          value: 6,
          label: "移除管理员",
        },
        {
          value: 7,
          label: "取消录入",
        },
        {
          value: 10,
          label: "删除第一枚指纹",
        },
        {
          value: 11,
          label: "删除第二枚指纹",
        },
        {
          value: 12,
          label: "删除第三枚指纹",
        },
        {
          value: 13,
          label: "移除人脸",
        },
        {
          value: 14,
          label: "移除掌静脉",
        },
        {
          value: -2,
          label: "下发更新人脸",
        },
        {
          value: -3,
          label: "下发更新第一枚指纹",
        },
        {
          value: -8,
          label: "下发更新第二枚指纹",
        },
        {
          value: -9,
          label: "下发更新第三枚指纹",
        },
        {
          value: -4,
          label: "下发更新掌静脉",
        },
        {
          value: -10,
          label: "记录速采",
        },
      ],
      optionsResult: [
        {
          value: 1,
          label: "处理中",
        },
        {
          value: 0,
          label: "下发成功",
        },
        {
          value: -1,
          label: "下发失败",
        },
      ],
      checkedCities: [],
      checklist: [],
      isIndeterminate: true,
      pickedList: [],
      pages: {}, //分页
      content: "",
      flag: 0,
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "nameText",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobText",
          id: 2,
        },
        {
          title: "部门",
          dataIndex: "dept",
          id: 3,
        },

        {
          title: "设备名称",
          dataIndex: "deviceName",
          id: 4,
        },
        {
          title: "云序列号",
          dataIndex: "sn",
          id: 5,
        },
        {
          title: "操作状态",
          dataIndex: "statusType",
          id: 6,
        },

        {
          title: "操作结果",
          dataIndex: "result",
          id: 7,
        },
        {
          title: "失败原因",
          dataIndex: "reason",
          id: 8,
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          id: 9,
        },
        {
          title: "完成时间",
          dataIndex: "timeFinish",
          id: 10,
        },
      ],
      formScree: {},
      mineStatusValue: [],
      selectTree: "",
      search: "", //搜索内容
      topleave: [], //部门列表
      crumbsList: [
        {
          name: "全部",
          departmentId: "",
        },
      ], //面包屑
      deptList: [],
      crumblist: [], //标题
      //面包屑
      crumbscopyList: [
        {
          name: "全部",
          departmentId: null,
        },
      ], //深层面包屑
      crumbscopyListcopy: [
        {
          name: "全部",
          departmentId: null,
        },
      ],
      checkList: [], //所选的列表

      tableSelection: [], //导出表格需要
      multipleSelection: [],
      multipleSelectionCopy: [], //多选操作，不做编译

      searchPass: false,
      searchName: false,
      searchRange: false,
    };
  },
  created() {
    this.getPassList({ pageNum: 1 });
  },
  watch: {
    "passform.likeString"(newName, oldName) {
      if (newName) {
        this.searchName = true;
      } else {
        this.searchName = false;
      }
    },
  },
  methods: {
    getPassList(form) {
      this.loading = true;
      http
        .get(
          "/dmsTask/list",
          Object.assign(form, { pageSize: 50 }, this.passform)
        )
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.pages = res.data;
            this.passList = res.data.list;

            this.$refs.multipleTable.doLayout();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    clearup() {
      this.passform.likeString = "";
      delete this.passform.likeString;
      this.onSearch();
    },
    // 判断是不是超过两分钟
    isexceed(givenTimeString) {
      // 将给定的时间字符串转换为Date对象
      var givenTime = new Date(givenTimeString);

      // 获取当前时间
      var now = new Date();

      // 比较两个时间戳的差值（毫秒）
      var diff = now - givenTime;

      // 将差值转换为分钟
      var diffMinutes = Math.ceil(diff / (1000 * 60));

      // 如果差值大于或等于两分钟，则返回true，否则返回false
      return diffMinutes >= 2;
    },

    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 表格下载之前
    async startDownload() {
      var that = this;
      let arr2 = [];
      var finalArr = [];
      for (let key of that.tableColumn) {
        arr2.push(key.key);
      }

      const loading = that.$loading({
        lock: true,
        text: "表格导出中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var total = that.pages.total;

      // 判断是否选择数据
      if (that.tableSelection.length > 0) {
        finalArr = that.tableSelection;
      } else {
        if (total >= 40000) {
          loading.close();
          that.$message.error("导出数据不能大于4万条");
          return;
        }
        try {
          var arr = await http.get(
            "/dmsTask/list",
            Object.assign(that.passform, {
              pageSize: total,
              pageNum: 1,
            })
          );
          if (arr.code == 200) {
            finalArr = arr.data.list;
          } else {
            loading.close();
          }
        } catch (e) {
          loading.close();
        }
      }

      if (finalArr.length > 0) {
        loading.close();
        finalArr.map((v) => {
          if (v.umsUser) {
            v.nameText = v.umsUser.name;
            v.jobText = "\xa0" + v.umsUser.jobNumber;
          }
          if (v.umsDepartment) {
            v.dept = v.umsDepartment.name;
          }
          if (v.dmsDevice) {
            v.deviceName = v.dmsDevice.name;
            v.sn = v.dmsDevice.sn;
          }
          v.createTime = "\xa0" + v.createTime;
          v.updateTime = "\xa0" + v.updateTime;
          v.reason = that.distributionReasult2(v.status);
          v.statusType = that.distributionStatus(v.type);
          v.result = that.distributionReasult(v.status);

          for (let i = 0; i < arr2.length; i++) {
            if (v[arr2[i]] == undefined) {
              v[arr2[i]] = "";
            }
          }
          for (let key in v) {
            if (v[key] == null) v[key] = "";
          }
        });
        exprotExcel.export(that.tableColumn, finalArr, "下发任务列表");
        that.$message.success("导出成功");
      } else {
        loading.close();
        that.$message.error("导出失败");
      }
    },

    //点击下级
    nextDepart(i) {
      this.crumblist.push(i);
      this.getAlldepart({ id: i.departmentId });
    },
    //回到上一级
    back() {},
    traUrl(url, num) {
      this.imgNum = num;
      this.openImg = true;
      this.bigImg = url;
    },

    // 页数改变
    changePage(e) {
      // let obj = { ...this.passform, };
      this.getPassList({ pageNum: e });
    },
    // 搜索集合
    onSearch() {
      this.getPassList({ pageNum: 1 });
    },
    clearType() {
      this.selectTreeName = "";
      delete this.passform.departmentId;
    },
    clearPeoType() {
      delete this.passform.peopleType;
    },
    // 选择方法
    selectChange(e) {
      var arrNew = [];
      var dataLength = this.mineStatusValue.length;
      var eleng = e.length;
      for (let i = 0; i < dataLength; i++) {
        for (let j = 0; j < eleng; j++) {
          if (e[j] === this.mineStatusValue[i].label) {
            arrNew.push(this.mineStatusValue[i]);
          }
        }
      }
      this.$refs.eltree.setCheckedNodes(arrNew); //设置勾选的值
    },
    handleCheckChange() {
      //调用获取节点
      let res2 = this.getSimpleCheckedNodes(this.$refs.eltree.store);
      // let res = this.$refs.eltree.getCheckedNodes(false, true); //这里两个true，1. 是否只是叶子节点 2. 是否包含半选节点（就是使得选择的时候不包含父节点）
      let arrLabel = [];
      let arr = [];
      res2.forEach((item) => {
        arrLabel.push(item.departmentName);
        arr.push(item);
      });
      this.formScree.departmentIdList = [];
      arr.map((item) => {
        this.formScree.departmentIdList.push(item.departmentId);
      });
      this.mineStatusValue = arr;
      this.formScree.name = arrLabel;
    },
    // 获取父节点的方法
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach((child) => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    // 选择上级部门点击方法
    addAdminHandleNodeClick(e) {
      this.selectTreeName = e.departmentName;
      this.passform.departmentId = e.departmentId;
      this.passform.peopleType = 0;
      this.$refs.multiSelect.blur();
    },
    // 下发状态
    distributionStatus(val) {
      switch (val) {
        case 0:
          return "下发人员";
        case 1:
          return "删除人员";
        case 2:
          return "登记人脸";
        case 3:
          return "录入第一枚指纹";
        case 4:
          return "登记掌静脉";
        case 5:
          return "登记管理员";
        case 6:
          return "移除管理员";
        case 7:
          return "取消录入";
        case 8:
          return "录入第二枚指纹";
        case 9:
          return "录入第三枚指纹";
        case 10:
          return "删除第一枚指纹";
        case 11:
          return "删除第二枚指纹";
        case 12:
          return "删除第三枚指纹";
        case 13:
          return "移除人脸";
        case 14:
          return "移除掌静脉";
        case -2:
          return "下发更新人脸";
        case -3:
          return "下发更新第一枚指纹";
        case -4:
          return "下发更新掌静脉";
        case -8:
          return "下发更新第二枚指纹";
        case -9:
          return "下发更新第三枚指纹";
        case -10:
          return "记录速采";
      }
    },
    // 下发结果
    distributionReasult(val) {
      switch (val) {
        case 0:
          return "下发成功";
        case 1:
          return "处理中";
        case 2:
          return "已接受";
        default:
          return "下发失败";
      }
    },
    distributionReasult2(val) {
      switch (val) {
        case -1:
          return "下发失败";
        case -2:
          return "参数错误";
        case -3:
          return "设备忙";
        default:
          return "- -";
      }
    },
    // 重置任务
    resetTask(id) {
      http
        .post("/dmsDevice/restart", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("重置成功");
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 判断任务是否超过30分钟
    judgeMinute(a) {
      // 将字符串转换为 Date 对象
      var givenTime = new Date(a);

      // 获取当前时间
      var currentTime = new Date();

      // 计算时间差（以毫秒为单位）
      var timeDifference = currentTime - givenTime;

      // 将时间差转换为分钟
      var timeDifferenceInMinutes = timeDifference / (1000 * 60);

      // 判断是否超过 30 分钟
      if (timeDifferenceInMinutes > 30) {
        return true;
      } else {
        return false;
      }
    },
    // 删除任务
    deleteTask(id) {
      var that = this;
      http
        .post("/dmsTask/delete", {
          ids: [id],
        })
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("删除成功");
            that.getPassList({ pageNum: 1 });
          }
        });
    },
    formatName(name) {
      return name.name;
    },
  },
};
</script>
<style scoped>
.record {
  padding: 16px 20px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f4f7fc;
}

.record_head {
  font-size: 14px;
  color: #5c5c5c;
}

.record_wrapper {
  width: calc(100vw - 290px);
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}

.record_search_wrapper {
  display: flex;
  padding: 20px 20px 18px 20px;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
}

.record_search {
  display: flex;
  justify-content: space-between;
}

.record_search_item {
  margin-right: 10px;
}

::v-deep .el-date-editor {
  width: 283px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

.approval_search {
  width: 243px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.approval_search:focus-within {
  border-color: #0096fa;
}

.record_export {
  margin-left: auto;
  margin-right: 0;
}

::v-deep .device_search_t .el-input {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

::v-deep .device_search_h .el-input {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

::v-deep .device_search_h .el-input__inner {
  border: none;
  height: 36px;
}

::v-deep .device_search_t .el-input__inner {
  border: none;
  height: 36px;
}

::v-deep .device_search_p .el-input__inner {
  border: 1px solid #d0d0d0;
  height: 36px;
}

::v-deep .record_search .el-range-editor .el-range-input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #b2b2b2;
}

.record_table_wrapper {
  flex: 1;
  position: relative;
  box-sizing: border-box;
  padding: 20px 18px 20px 20px;
  width: calc(100vw - 330px);
  font-size: 16px;
}

.record_table {
  width: 100%;
  height: 100%;
  position: absolute;
  white-space: nowrap;
  font-size: 16px;
}

.device_type {
  display: inline-block;
  width: 48px;
  height: 26px;
  background: #e0ecff;
  opacity: 1;
  text-align: center;
  color: #0058ff;
  line-height: 26px;
}

.crumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #5c5c5c;
}

.newpagination {
  height: 50px;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.newpagination_text {
  font-size: 16px;
  color: #5c5c5c;
}

::v-deep .newpagination .el-pagination {
  margin: 0 20px 30px auto;
}

.filtrate_wrapper {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

.filtrate {
  display: flex;
  align-items: center;
}

.filtrate_single {
  margin: 5px 3px;
  padding: 3px 6px;
  color: #0058ff;
  font-size: 12px;
  display: flex;
  align-items: center;
  background: #e0ecff;
  border-radius: 4px;
  max-width: 190px;
  height: 20px;
}

.filtrate_single_a {
  width: auto;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filtrate_icon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

::v-deep .el-link .is-disabled:focus {
  color: wheat;
}

::v-deep.el-table .el-table__body td .cell:empty::after,
.el-table .el-table__body td .cell > span:empty::after,
.el-table .el-table__body td .cell .approval:empty::after,
.el-table .el-table__body td .cell div:empty::after,
.el-table .el-table__body td .cell .line-limit-length:empty::after {
  content: "--";
}

::v-deep .el-icon-circle-close {
  width: 30px;
  background: #ffffff;
}

.setstyle {
  min-height: 200px;
  overflow: auto;
  padding: 0;
}

::v-deep .record_search .el-button--primary {
  height: 38px;
  line-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 20px;
}
.dept {
  width: 130px;
  overflow: hidden; /* 隐藏超出部分的内容 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
</style>