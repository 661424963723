import { render, staticRenderFns } from "./chockin.vue?vue&type=template&id=4848f44d&scoped=true"
import script from "./chockin.vue?vue&type=script&lang=js"
export * from "./chockin.vue?vue&type=script&lang=js"
import style0 from "./chockin.vue?vue&type=style&index=0&id=4848f44d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4848f44d",
  null
  
)

export default component.exports